@media (min-width: 700px ) {
  .form {
    width: 34.5rem !important;
  }

  .form p {
    height: 31.375rem !important;
  }

  .preview {
    height: auto;
  }
}

.formContainer {
  padding: 0.5rem;
  width: 100%;
}

.form {
  width: 100%;
  background: #ccc;
  margin: 0 auto;
  padding: 1.25rem;
  border: 1px solid black;
  font-family: sans-serif;
}

.form ol {
  padding-left: 0;
  margin-bottom: 12px;
  height: 100%;
}

.form ol p {
  margin: 1rem 0;
}

.form li, .preview > p {
  background: #eee;
  display: flex;
  justify-content: center;
  list-style-type: none;
  border: 1px solid black;
  margin-bottom: 0 !important;
  height: 100%;
}

.preview {
  height: auto;
  max-height: 70%;
  margin-bottom: 0.375rem;
  margin-top: 1rem;
}

.form p {
  height: 50vh;
  line-height: 32px;
  padding-left: 10px;

}

.form label {
  margin-bottom: 1.1875rem;
  color: black;
}

.form label, .form button {
  background-color: #7F9CCB;
  padding: 0.25rem 0.625rem;
  border-radius: 5px;
  border: 1px ridge black;
  font-size: 0.8rem;
  height: auto;
}

.form label:hover, .form button:not(:disabled):hover {
  background-color: #2D5BA3;
  color: white;
}

.form label:active, .form button:not(:disabled):active {
  background-color: #0D3F8F;
  color: white;
}

.image {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}