.body {
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main {
  height: calc(100% - 54px);
  overflow: auto;
  overflow-x: hidden;
}
